import * as React from "react";
import Section from "@components/Section";
import {useColorMode} from "theme-ui";

import {Box, Text} from "rebass";
import IContentProps from "@digitaltransformation/qone.pages.layout/build/container/interfaces/IContentProps";

interface ICoverTitleContent
    extends IContentProps
{
    fill: string,
}

class CoverTitleContent extends React.Component<ICoverTitleContent> {
    public render() {
        return (
            <>
                <Section relative>
                    <Box style={{
                        padding: '10vh 0 10vh 0',
                    }}>
                        <Text
                            style={{
                                padding: '1em 0',
                                fontWeight: 900,
                                fontSize: '1em',
                                color: this.props.fill,
                                textTransform: 'uppercase'
                            }}
                        >
                            {this.props.subtitle?.children}
                        </Text>
                        <Text
                            children={this.props.title.children}
                            style={{
                                padding: '1em 0',
                                color: this.props.fill,
                                fontSize: '3em',
                                fontWeight: 100,
                            }}
                        />
                        <Box
                            width={'5em'}
                            height={'0.25em'}
                            style={{
                                margin: '1em 0',
                                borderRadius: '5px',
                                background: 'linear-gradient(to right, #1fa2ff, #12d8fa)'
                            }}
                        />
                        <Text
                            style={{
                                padding: '1em 0',
                                maxWidth: '25em',
                                color: this.props.fill,
                                fontSize: '1.25em',
                                fontWeight: 100,
                                lineHeight: '1.25'
                            }}
                        >
                            {this.props.text?.children}
                        </Text>
                    </Box>
                </Section>
            </>
        )
    }
}

const StateWrapper = component => props => {
    const colorMode = useColorMode();
    const isDark = colorMode[0] === 'dark';
    const fill = isDark ? "#fff" : "#000";

    return <CoverTitleContent {...props} fill={fill}/>
};

export default StateWrapper(CoverTitleContent);
