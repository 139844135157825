import * as React from 'react';
import MainCoverImpl from "../@narative/gatsby-theme-novela/sections/cover/MainCoverImpl";
import Layout from "../@narative/gatsby-theme-novela/components/Layout/Layout";
import Section from "@components/Section";
import Headings from "@components/Headings";
import CoverTitleContent from "../content/CoverTitleContent";

import themeProps from '../gatsby-plugin-theme-ui/index';
import styled from "styled-components";
import ReactMarkdown from "react-markdown";

import {graphql, StaticQuery} from "gatsby";
import {Box} from "@rebass/grid";

const pageQuery = graphql`
    query {
      file(relativePath: { eq: "pages/tos.md" }) {
        publicURL
        relativePath
        childMarkdownRemark {
          frontmatter {
            slug
            title
            date
            excerpt
          }
          rawMarkdownBody
        }
      }
    }
`

class Terms extends React.Component {
    private static Container = styled.div`
      * {
        padding: 0.5em;
        line-height: 1.25;
        font-size: 11pt;
        font-family: 'Open Sans', sans-serif;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none;
      };
    `

    private static Headings = styled.div`
       margin: 2em 0;
       text-align: left;
       & > h3, h4, h5, h6 {
          font-family: ${themeProps.fonts.sansSerif};
       }
    `

    public render() {
        return <StaticQuery query={pageQuery} render={(data) =>
            <Layout>
                <MainCoverImpl>
                    <CoverTitleContent
                        title={{
                            children: data.file.childMarkdownRemark.frontmatter.title 
                        }}
                        subtitle={{
                            children: <>
                                Last updated on {data.file.childMarkdownRemark.frontmatter.date}
                            </>
                        }}
                        text={{
                            children: data.file.childMarkdownRemark.frontmatter.excerpt
                        }}
                    />
                </MainCoverImpl>
                <Section>
                    <Terms.Container>
                        <Box width={[1, 2/3]}>
                            <StyledMarkdown 
                                source={data.file.childMarkdownRemark.rawMarkdownBody}/>
                        </Box>
                    </Terms.Container>
                </Section>
            </Layout>
        }/>;
    }
}

export default Terms;

const StyledMarkdown = styled(ReactMarkdown)`
  color: ${themeProps.colors.silver};

  & a {
    color: ${themeProps.colors.accent};
  }
`
